<template>
  <div class="flex flex-col space-y-2 items-center">
    <div class="w-full xl:w-1/2">
      <a-button @click="$router.back()">
        {{ lang.common.back }}
      </a-button>
      <form
        class="space-y-4"
        @submit.prevent="handleStore"
        @keydown="removeError($event.target.id)">
        <h3 class="my-4 text-2xl">{{ lang.activities.create_activity }}</h3>
        <a-input
          :label="lang.activities.table.title"
          required
          class="mb-4"
          id="title"
          :disabled="loading"
          :error="error('title')"
          v-model="activity.title" />

        <a-input
          :label="lang.activities.table.description"
          required
          class="mb-4"
          id="description"
          :disabled="loading"
          :error="error('description')"
          v-model="activity.description" />

        <a-input
          :label="lang.activities.table.start_date"
          class="mb-4"
          required
          id="start_date"
          :disabled="loading"
          type="date"
          :error="error('start_date')"
          v-model="activity.start_date" />

        <a-input
          :label="lang.activities.table.end_date"
          class="mb-4"
          id="end_date"
          :disabled="loading || !activity.start_date"
          type="date"
          :error="error('end_date')"
          v-model="activity.end_date"
          :min="activity.start_date" />

        <a-input
          type="textarea"
          :label="lang.activities.table.observation"
          id="observation"
          :disabled="loading"
          v-model="activity.observation" />

        <div class="flex space-x-2">
          <a-button mode="primary" type="submit" :loading="loading">
            {{ lang.common.save }}
          </a-button>

          <a-button outlined @click="$router.back()">
            {{ lang.common.cancel }}
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    activity: {
      title: '',
      description: '',
      start_date: '',
      end_date: ''
    }
  }),
  computed: {
    ...mapGetters(['hasError', 'error', 'lang']),
    ...mapState({
      activities: state => state.activities.pagination.data,
      currentActivityOnState: state => state.activities.current,
      loading: state => state.activities.loading
    })
  },
  methods: {
    ...mapActions({
      store: 'activities/store',
      removeError: 'removeError',
    }),
    handleStore() {
      this.store(this.activity)
        .then(() => {
          this.$router.back()
        })
    }
  }
}
</script>
